<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 56.76 56.76"
  >
    <g id="Calque_2"
       data-name="Calque 2"
    >
      <g id="grouper">
        <path class="cls-1"
              d="M28.61,42.26c-4.74,0-8.59-3.35-8.59-7.48h3.64c0,2.12,2.22,3.84,4.95,3.84s5-1.72,5-3.84c0-2.37-1.9-3.84-5-3.84-5.93,0-8.59-3.76-8.59-7.48C20,19.33,23.87,16,28.61,16s8.59,3.35,8.59,7.48H33.56c0-2.12-2.22-3.84-5-3.84s-4.95,1.72-4.95,3.84c0,2.44,1.81,3.84,4.95,3.84,5.06,0,8.59,3.08,8.59,7.48C37.2,38.91,33.34,42.26,28.61,42.26Z"
        /><rect class="cls-1"
                x="26.79"
                y="12.79"
                width="3.64"
                height="3.89"
        /><rect class="cls-1"
                x="26.79"
                y="41.56"
                width="3.64"
                height="3.89"
        /><circle class="cls-2"
                  cx="28.38"
                  cy="28.38"
                  r="25.88"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: #292828;
  stroke-width: 0.94px;
}
.cls-1,
.cls-2 {
  stroke: #292828;
  stroke-miterlimit: 10;
}
.cls-2 {
  fill: none;
  stroke-width: 5px;
}
</style>
